<template lang="pug">
	footer
		.container
			.row
				.col-12
					.in-row
						p Powered By:
						img(src="@/assets/logo-ews.png", alt="")
</template>

<script>
	export default {
		name: 'Footer'
	}
</script>