<template lang="pug">
	.sidebar
		p Відділ освіти:
		ul
			li Керівництво
			li Структура
			li Контакти

		p Публічна інформація:
		ul
			li Нормативно-правова база доступу до публічної інформації
			li Штатний розпис
			li Список закладів освіти
			li Положення про відділ освіти, молодіжної політики та спорту

		p Поточна діяльність:
		ul
			li Підготовка до 2021-2022 навчального року ЗЗСО
			li Підготовка до 2021-2022 навчального року ЗДО та ЗПО
			li Нова українська школа
			li Дошкільна освіта
			li Освітні програми та навчальні плани
			li ЗНО та ДПА 
			li Методична робота

		p Документи

		ul
			li Оголошення
			li Накази відділу освіти
			li Розпорядження 
			li Інклюзивна освіта
			li Статистичні звіти

</template>

<script>
	export default {
		name: 'Sidebar'
	}
</script>