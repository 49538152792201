import axios from 'axios'
const apiClient = axios.create({
	baseURL: 'https://vomps.easy-web.solutions/api/'
});



// apiClient.interceptors.request.use(request => {
// 	const token = window.store.state.Token.access;
// 	if (token) {
// 		request.headers.Authorization = 'Bearer ' + token
// 	}
// 	return request
// })

// apiClient.interceptors.response.use((response) => {
// 	return response;
// }, (error) => {
// 	if (error.response.status) {
// 		switch (error.response.status) {
// 			case 401:{
// 				const refresh = window.store.state.Token.refresh;
// 				window.api.get("auth/token/refresh", {headers: {'Authorization': 'Refresh ' + refresh}}).then((response) => {
// 					if (response.status == 200) {
// 						window.Token.settoken(response.data);
// 						window.Token.init();
// 					}
// 				})
// 				break;
// 			}
// 			case 405:{
// 				window.Token.deletetoken();
// 				window.Auth.logout();
// 				router.push('login');
// 			}
// 		}
// 	}
// });

export default apiClient