<template lang="pug">
	.gallery
		.date-title
			h4 {{ gallery.name }}
			i {{ gallery.date_time }}

		.album-description(v-html="gallery.description")

		VueSlickCarousel(
			:arrows="true",
			:dots="true",
			v-if="gallery.photos.length > 1",
			:variableWidth="true",
			:centerMode="true"
		).main-slider
			.slide(v-for="item in gallery.photos")
				.photo
					img(:src="'https://vomps.easy-web.solutions' + item.full")

</template>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'

	export default {
		name: 'Gallery',
		data(){
			return{
				slug: this.$route.params.gallery,
				gallery: []
			}
		},
		components:{
			VueSlickCarousel
		},
		created: function () {
			this.getGallery();
		},
		methods: {
			getGallery(){
				window.api.get('photoalbum/' + this.slug + '/')
					.then(gallery => {
						this.gallery = gallery.data
					})
					.catch(galleryError => {
						console.log(galleryError);
					})
			}
		}
	}
</script>
