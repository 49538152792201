<template lang="pug">
	.single-post
		.text
			h2 {{ post.title }}
			i {{ post.created_at }}
			div(v-html="post.text")
			
</template>

<script>
	export default {
		name: 'Home',
		data(){
			return{
				slug: this.$route.params.post,
				post: ''
			}
		},
		created: function () {
			window.api.get('post/' + this.slug + '/')
				.then(post => {
					this.post = post.data
				})
				.catch(postError => {
					console.log(postError);
				})
		}
	}
</script>
