<template lang="pug">
	.posts-list
		.single(v-for="item in allPosts")
			router-link(:to='{params: { page: item.slug }}').cover
				img(:src="item.cover_thumb", alt="", v-if="item.cover_thumb")
				img(v-else, src="@/assets/no-image.svg", alt="")

			.info
				.date-title
					router-link(:to='{name: "SinglePost", params: { post: item.slug }}')
						h4 {{ item.name }}
					i {{ item.created_at }}
				.text(v-html="item.content_short")

		.not-have-posts(v-if="allPosts.length == 0")
			h3 Ще немає записів вибраної категорії

</template>

<script>
	export default {
		name: 'Posts',
		data(){
			return{
				slug: this.$route.params.page,
				allPosts: []
			}
		},
		watch: {
			$route(to) {
				this.slug = to.params.page;
				this.getPosts();
			}
		},
		created: function () {
			this.getPosts();
		},
		methods: {
			getPosts(){
				window.api.get('get_pages/' + this.slug + '/')
					.then(posts => {
						this.allPosts = posts.data
					})
					.catch(postsError => {
						console.log(postsError);
					})
			}
		}
	}
</script>
