<template lang="pug">
	div#app
		<Header/>

		.main
			.container
				.row
					.col-lg-4
						<Sidebar/>

					.col-lg-8
						<router-view/>

		<Footer/>

</template>

<style lang="scss">
	@import "./mainStyles/main.scss";
</style>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Sidebar from './components/Sidebar'

export default {
	name: 'App',
	components: {
		Header,
		Footer,
		Sidebar
	}
}
</script>
