<template lang="pug">
	.photos-gallery
		router-link(v-for="item in photosList", :to='{name: "Gallery", params: { gallery: item.slug }}').single-prev
			.cover
				img(:src="'https://vomps.easy-web.solutions/' + item.cover", alt="")

			.info
				p {{ item.name | truncate(12, '...') }}
				i {{ item.date_time }}
</template>

<script>
	export default {
		name: 'Photos',
		data(){
			return{
				photosList: []
			}
		},
		created: function () {
			window.api.get('photoalbums/')
				.then(photos => {
					this.photosList = photos.data
				})
				.catch(photosError => {
					console.log(photosError);
				})
		},
		filters: {
			truncate: function (text, length, suffix) {
				if (text.length > length) {
					return text.substring(0, length) + suffix;
				} else {
					return text;
				}
			}
		}
	}
</script>
