<template lang="pug">
	.top
		header
			.container
				.row
					.col-12
						.inline
							.logo
								img(src="@/assets/logo.png", alt="")
						
							nav
								router-link(to="/", v-bind:class="{ active: '/' == $route.path}") Головна
								router-link(
									v-for="item in menu",
									v-bind:class="{ active: item.slug == $route.params.page}",
									:to='{name: "Posts", params: { page: item.slug }}',
								) {{ item.name }}
								router-link(to="/photoalbums", v-bind:class="{ active: '/photoalbums' == $route.path}") Фото


		.name
			.container
				.row
					.col-12
						p Відділ освіти, молодіжної політики та спорту Заболотівської селищної ради
</template>

<script>
	export default {
		name: 'Header',
		data(){
			return{
				menu: []
			}
		},
		created: function () {
			window.api.get('get_menu/')
				.then(menu_items => {
					this.menu = menu_items.data
				})
				.catch(menuError => {
					console.log(menuError);
				})
		}
	}
</script>