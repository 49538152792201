import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Index/'
// import News from '../pages/News/'
import Posts from '../pages/Posts/'
import SinglePost from '../pages/SinglePost/'
import Photos from '../pages/Photos/'
import Gallery from '../pages/Gallery/'
import MainPost from '../pages/MainPost/'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		id: 1,
		component: Home
	},
	{
		path: '*',
		redirect: 'Home'
	},
	{
		path: '/photoalbums',
		name: 'Photos',
		id: 2,
		component: Photos
	},
	{
		path: '/:page',
		name: 'Posts',
		id: 3,
		component: Posts
	},
	{
		path: '/post/:post',
		name: 'SinglePost',
		id: 4,
		component: SinglePost
	},
	{
		path: '/photoalbums/:gallery',
		name: 'Gallery',
		id: 5,
		component: Gallery
	},
	{
		path: '/post-main/:post',
		name: 'MainPost',
		id: 6,
		component: MainPost
	}
	// {
	// 	path: '/',
	// 	name: '',
	// 	id: ,
	// 	component: 
	// }
]

// AppointmentCalendar

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
